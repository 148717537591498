import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TimHeymann from './images/TimHeymann.png';
import Logo from './images/logo_bridges-4c-white.svg';

// Custom hook to parse query parameters
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function UploadForm() {
  const [resumeFile, setResumeFile] = useState(null);
  const [otherFiles, setOtherFiles] = useState([]);
  const [clientSlug, setClientSlug] = useState('');
  const [consent, setConsent] = useState(false);
  const [resumeError, setResumeError] = useState('');
  const [otherFilesError, setOtherFilesError] = useState('');
  const [clientSlugError, setClientSlugError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const MAX_OTHER_FILES = 3; // Maximum number of additional files allowed

  // Extract client slug from URL parameters
  const query = useQuery();

  useEffect(() => {
    const slugFromUrl = query.get('client') || '';
    setClientSlug(slugFromUrl);
    if (!slugFromUrl) {
      setClientSlugError('Bitte öffnen Sie dieses Formular durch Klicken auf den Link in der E-Mail, die Sie erhalten haben, oder kopieren Sie den Link. Nur so können wir Ihre Dokumente richtig zuordnen.');
    } else {
      setClientSlugError('');
    }
  }, [query]);

  const handleResumeFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === 'application/pdf') {
      setResumeFile(selectedFile);
      setResumeError('');
      setSuccessMessage('');
    } else {
      setResumeFile(null);
      setResumeError('Bitte wählen Sie eine PDF Datei aus.');
      setSuccessMessage('');
    }
  };

  const handleOtherFilesChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    
    if (selectedFiles.length > MAX_OTHER_FILES) {
      setOtherFilesError(`Bitte wählen Sie maximal ${MAX_OTHER_FILES} zusätzliche Dateien aus.`);
      setOtherFiles([]);
      e.target.value = ''; // Reset the file input
      return;
    }

    const pdfFiles = selectedFiles.filter(file => file.type === 'application/pdf');
    if (pdfFiles.length === selectedFiles.length) {
      setOtherFiles(pdfFiles);
      setOtherFilesError('');
    } else {
      setOtherFiles([]);
      setOtherFilesError('Bitte wählen Sie nur PDF Dateien aus.');
    }
    setSuccessMessage('');
  };

  const handleConsentChange = (e) => {
    setConsent(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (!clientSlug) {
      setClientSlugError('Bitte öffnen Sie dieses Formular durch Klicken auf den Link in der E-Mail, die Sie erhalten haben. Nur so können wir Ihre Dokumente richtig zuordnen.');
      return;
    }

    if (!resumeFile) {
      setResumeError('Bitte wählen Sie einen Lebenslauf als PDF Datei aus.');
      setSuccessMessage('');
      return;
    }
    if (!consent) {
      setResumeError('Sie müssen die Datenschutzerklärung akzeptieren, bevor Sie fortfahren.');
      setSuccessMessage('');
      return;
    }

    const formData = new FormData();
    formData.append('resume', resumeFile);
    otherFiles.forEach((file, index) => {
      formData.append(`otherFile${index}`, file);
    });
    formData.append('clientSlug', clientSlug);

    fetch('https://hook.eu2.make.com/ncnpcnb157cxhpdc8or4wyy2xps2zgq3', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        console.log('Success:', data);
        setSuccessMessage('Ihre Dokumente wurden erfolgreich hochgeladen!');
        setResumeError('');
        setOtherFilesError('');
        setClientSlugError('');
        setResumeFile(null);
        setOtherFiles([]);
        setConsent(false);
        // Reset file inputs
        document.getElementById('resumeFileInput').value = '';
        document.getElementById('otherFilesInput').value = '';
      })
      .catch((error) => {
        console.error('Error:', error);
        setResumeError('Fehler beim Hochladen der Dateien. Bitte versuchen Sie es erneut.');
        setSuccessMessage('');
      });
  };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className='form-wrapper'>
        <div className='form-left'>
          <img className='m-6' width={152} height={72} src={Logo} alt="Bridges Logo" />
          <img className='image-heymann p-2 mt-4' src={TimHeymann} alt="Bridges" />
          <div className='contact-data'>
            <div className='mx-5' id='name'>Tim Heymann</div>
            <div className='mx-5' id='position'>Geschäftsführer</div>
            <div className='contact-details'>
              <div id='title'>Direkter Kontakt</div>
              <div id='email'><a href='mailto:tim.heymann@raising-standards.com' title='Schreiben Sie mir eine E-Mail'>tim.heymann@raising-standards.com</a></div>
              <div id='phone'><a href='tel:+49 157 923 967 65' title='Rufen Sie mich gerne an'>+49 157 923 967 65</a></div>
              <div id='address'><a href='https://g.co/kgs/SkcZohe' title='Hier finden Sie Uns'>Rudolfplatz 3, 50674 Köln</a></div>
            </div>
          </div>
        </div>
        <div className='form-right'>
          <form
            onSubmit={handleSubmit}
            className="w-full max-w-sm p-6 shadow-md"
          >
            <h2 className="text-lg leading-5 font-bold mb-2">Sicherer Dokumenten-Upload</h2>
            <p className='text-sm text-gray-700 mb-6'>Laden Sie hier Ihren Lebenslauf und andere Dokumente als PDF Datei hoch.</p>
            
            {clientSlugError && (
              <div className="text-xs mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
                {clientSlugError}
              </div>
            )}
            
            {successMessage && (
              <div className="text-xs mb-4 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
                {successMessage}
              </div>
            )}

            <div className="mb-4 p-4 bg-gray-50 rounded-lg border-2 border-gray-200">
              <label
                htmlFor="resumeFileInput"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Hier laden Sie Ihren Lebenslauf hoch:
              </label>
              <input
                type="file"
                id="resumeFileInput"
                onChange={handleResumeFileChange}
                accept=".pdf"
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              />
              {resumeError && <p className="text-red-500 text-xs italic mt-1">{resumeError}</p>}
            </div>

            <div className="mb-4 p-4 bg-gray-50 rounded-lg border-2 border-gray-200">
              <label
                htmlFor="otherFilesInput"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Weitere Dokumente (optional):
              </label>
              <p className='text-xs text-gray-700 mb-2'>
                Falls Sie weitere Dokumente hochladen möchten, laden Sie über das folgende Feld eine oder mehrere Dateien hoch (maximal {MAX_OTHER_FILES} Dateien).
              </p>
              <input
                type="file"
                id="otherFilesInput"
                onChange={handleOtherFilesChange}
                accept=".pdf"
                multiple
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              />
              {otherFilesError && <p className="text-red-500 text-xs italic mt-1">{otherFilesError}</p>}
            </div>

            <div className="mb-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  checked={consent}
                  onChange={handleConsentChange}
                  className="form-checkbox"
                />
                <span className="ml-2 text-xs text-gray-500">
                  Hiermit akzeptiere ich die{' '}
                  <a href="https://www.raising-standards.com/datenschutzerkl%C3%A4rung" target="_blank" rel="noreferrer" className="underline" id='privacy'>
                    Datenschutzerklärungen
                  </a>
                  {' '}der Bridges RS GmbH
                </span>
              </label>
            </div>

            <button
              type="submit"
              className="w-full p-4 text-white font-bold rounded-lg focus:outline-none focus:ring focus:border-blue-300"
              disabled={!clientSlug}
            >
              Dokumente hochladen
            </button>
            <p className='text-xs text-gray-500 mt-8'>Ihre personenbezogenen Daten werden von uns vertraulich behandelt und ausschließlich gemäß unserer Datenschutzerklärung verwendet. Mit dem Hochladen Ihrer Dokumente stimmen Sie der Verarbeitung Ihrer Daten zu.</p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UploadForm;